/**
 * Translate description: Sidebar admin-menu
 */

import { useState, Fragment, useEffect } from 'react';
import { useLocation, Link as ReactLink } from "react-router-dom";
import { useLanguageContext } from "../../context/LanguageContext";

import Box from '@mui/joy/Box';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import StorefrontRoundedIcon from '@mui/icons-material/StorefrontRounded';
import LeaderboardRoundedIcon from '@mui/icons-material/LeaderboardRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ModeStandbyRoundedIcon from '@mui/icons-material/ModeStandbyRounded';
import DangerousRoundedIcon from '@mui/icons-material/DangerousRounded';
// import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
// import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
// import SupportRoundedIcon from '@mui/icons-material/SupportRounded';
// import TranslateIcon from '@mui/icons-material/Translate';
// import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
// import CategoryRoundedIcon from '@mui/icons-material/CategoryRounded';
// import TagRoundedIcon from '@mui/icons-material/TagRounded';
// import RssFeedRoundedIcon from '@mui/icons-material/RssFeedRounded';
// import BrandingWatermarkRoundedIcon from '@mui/icons-material/BrandingWatermarkRounded';
// import ViewQuiltRoundedIcon from '@mui/icons-material/ViewQuiltRounded';
// import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
// import ViewCarouselRoundedIcon from '@mui/icons-material/ViewCarouselRounded';
// import { IconButton, ListSubheader, Stack } from '@mui/joy';
// import { Collapse } from '@mui/material';

function Toggler({ defaultExpanded = false, locationWhereOpened = [], renderToggle, children }) {
  const [open, setOpen] = useState(defaultExpanded);

  useEffect(() => {
    setOpen(locationWhereOpened.includes(location.pathname));
  }, [location.pathname])

  return (
    <Fragment>
      {renderToggle({ open, setOpen })}
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: open ? '1fr' : '0fr',
          transition: '0.2s ease',
          '& > *': {
            overflow: 'hidden',
          },
        }}
      >
        {children}
      </Box>
    </Fragment>
  );
}
export default function SidebarMenu() {
  const { t } = useLanguageContext();
  const location = useLocation();
  const linkStyle = { textDecoration: 'none', display: 'block', width: '100%' };
  const [open, setOpen] = useState(false);
  return (
    <>
      <List
        size="sm"
        sx={{
          gap: 1,
          '--List-nestedInsetStart': '30px',
          '--ListItem-radius': (theme) => theme.vars.radius.sm,
        }}
      >
        {/* <ListItem>
          <ReactLink to="/" style={linkStyle}>
            <ListItemButton selected={location.pathname === '/'}>
              <HomeRoundedIcon />
              <ListItemContent>
                <Typography level="title-sm">{t("Home")}</Typography>
              </ListItemContent>
            </ListItemButton>
          </ReactLink>
        </ListItem> */}

        <ListItem>
          <ReactLink to="/advertisers" style={linkStyle}>
            <ListItemButton selected={location.pathname === '/advertisers'}>
              <StorefrontRoundedIcon />
              <ListItemContent>
                <Typography level="title-sm">{t("Domains")}</Typography>
              </ListItemContent>
            </ListItemButton>
          </ReactLink>
        </ListItem>
        <ListItem>
          <ReactLink to="/groups" style={linkStyle}>
            <ListItemButton selected={location.pathname === '/groups'}>
              <StorefrontRoundedIcon />
              <ListItemContent>
                <Typography level="title-sm">{t("Site Groups")}</Typography>
              </ListItemContent>
            </ListItemButton>
          </ReactLink>
        </ListItem>
        <ListItem sx={{ flexDirection: 'column', '--NestedList-marginLeft': 'calc(-1 * var(--ListItem-paddingLeft))', '--NestedList-marginRight': 'calc(-1 * var(--ListItem-paddingRight))', gap: 0 }}>
          <ListItemButton onClick={() => setOpen(prev => !prev)}>
            <LeaderboardRoundedIcon />
            <ListItemContent>
              <Typography level="title-sm">{t("Analytics")}</Typography>
            </ListItemContent>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </ListItemButton>
          <Box width={'100%'} sx={{ display: 'grid', gridTemplateRows: open ? '1fr' : '0fr', transition: open ? 'all 0.2s ease 0s' : '0.2s ease', '& > *': { overflow: 'hidden' } }}>
            <List sx={{ gap: '4px', '--ListItem-minHeight': '32px', '--ListItem-radius': (theme) => theme.vars.radius.sm, ml: 'var(--NestedList-marginLeft)', mr: 'var(--NestedList-marginRight)', padding: 0 }}>
              <ListItem sx={{ mt: '4px'}}>
                <ReactLink to="/analytics/advertisers" style={linkStyle}>
                  <ListItemButton sx={{ pl: 4.7 }} selected={location.pathname === '/analytics/advertisers'}>
                    <ListItemContent>
                      <Typography level="title-sm">{t("Domains")}</Typography>
                    </ListItemContent>
                  </ListItemButton>
                </ReactLink>
              </ListItem>
              <ListItem sx={{ mt: '4px'}}>
                <ReactLink to="/analytics/groups" style={linkStyle}>
                  <ListItemButton sx={{ pl: 4.7 }} selected={location.pathname === '/analytics/groups'}>
                    <ListItemContent>
                      <Typography level="title-sm">{t("Site Groups")}</Typography>
                    </ListItemContent>
                  </ListItemButton>
                </ReactLink>
              </ListItem>
              <ListItem>
                <ReactLink to="/analytics/visitors" style={linkStyle}>
                  <ListItemButton sx={{ pl: 4.7 }} selected={location.pathname === '/analytics/visitors'}>
                    <ListItemContent>
                      <Typography level="title-sm">{t("Visitors")}</Typography>
                    </ListItemContent>
                  </ListItemButton>
                </ReactLink>
              </ListItem>
            </List>
          </Box>
        </ListItem>
        <ListItem>
          <ReactLink to="/target_actions" style={linkStyle}>
            <ListItemButton selected={location.pathname === '/target_actions'}>
              <ModeStandbyRoundedIcon/>
              <ListItemContent>
                <Typography level="title-sm">{t("Target Actions")}</Typography>
              </ListItemContent>
            </ListItemButton>
          </ReactLink>
        </ListItem>
        <ListItem>
          <ReactLink to="/antifraud" style={linkStyle}>
            <ListItemButton selected={location.pathname === '/antifraud'}>
              <DangerousRoundedIcon/>
              <ListItemContent>
                <Typography level="title-sm">{t("Antifraud")}</Typography>
              </ListItemContent>
            </ListItemButton>
          </ReactLink>
        </ListItem>
        <ListItem>
          <ReactLink to="/users" style={linkStyle}>
            <ListItemButton selected={location.pathname === '/users'}>
              <PeopleAltRoundedIcon />
              <ListItemContent>
                <Typography level="title-sm">{t("Users")}</Typography>
              </ListItemContent>
            </ListItemButton>
          </ReactLink>
        </ListItem>
      </List>

      <List
        size="sm"
        sx={{
          mt: 'auto',
          flexGrow: 0,
          '--ListItem-radius': (theme) => theme.vars.radius.sm,
          '--List-gap': '4px',
          mb: 2,
        }}
      >
        {/* <ListItem>
          <ReactLink to="/support" style={linkStyle}>
            <ListItemButton selected={location.pathname.indexOf('/support') === 0}>
              <SupportRoundedIcon />
              {t("Support")}
            </ListItemButton>
          </ReactLink>
        </ListItem> */}
        <ListItem>
          <ReactLink to="/settings" style={linkStyle}>
            <ListItemButton selected={location.pathname.indexOf('/settings') === 0}>
              <SettingsRoundedIcon />
              {t("Settings")}
            </ListItemButton>
          </ReactLink>
        </ListItem>
      </List>
    </>
  )
}